$(document).on('turbolinks:load',function() {

  if($("#select-meeting-attendees, #select-meeting-notifees").length > 0){
    attendeeEvents = {
      onItemAdd: function(values) { meetingValueAdded(values, "attendee") },
      onItemRemove: function(values) { meetingValueRemoved(values, "attendee") }
    }

    notifeeEvents = {
      onItemAdd: function(values) { meetingValueAdded(values, "notifee") },
      onItemRemove: function(values) { meetingValueRemoved(values, "notifee") }
    }

    new TomSelect("#select-meeting-attendees", { ...attendeeEvents, ...meetingAttendeesConfig });
    new TomSelect("#select-meeting-notifees", { ...notifeeEvents, ...meetingNotifeesConfig });

  }

  if($("#select-notetaker").length > 0){
    new TomSelect("#select-notetaker", meetingNotetakerConfig);
  }

  if($("#select-meeting-inviter").length > 0){
    new TomSelect("#select-meeting-inviter", meetingInviterConfig);
  }


  $(document).on('click',"#agenda_issue_list li.agenda-issue",function(event) {
    agendaModal = $(this).parents("#newAgendaModal")
    agendaModal.find("#select-agenda-issue").val(this.getAttribute('value'))
    agendaModal.find("#agenda_agenda_issue_title").val(this.innerText).attr("new_issue", "false")
    agendaModal.find("#agenda-issue-list-block").css("display", "none")
    agendaModal.find("#agenda_issue_list").remove()
  });

  $(document).on('focus',"#newAgendaModal #agenda_agenda_issue_title",function(event) {
    $(this).parents("#newAgendaModal").find("#agenda-issue-list-block").css("display", "block")
  });

  $(document).on('click',"#agenda_issue_list li.add-agenda-issue",function(event) {
    agendaModal = $(this).parents("#newAgendaModal")
    agendaModal.find("#select-agenda-issue").val("")
    agendaModal.find("#agenda_agenda_issue_title").attr("new_issue", "true")
    agendaModal.find("#agenda-issue-list-block").css("display", "none")
    agendaModal.find("#agenda_title").val(agendaModal.find("#agenda_agenda_issue_title").val())
    agendaModal.find("#agenda_issue_list").remove()
  });

  // On Backspace click clear search field if any Issue is selected from list or added new issue. 
  $(document).on('keydown',"#newAgendaModal #agenda_agenda_issue_title",function(event) {
    if(event.key == "Backspace"){
      agendaModal = $(this).parents("#newAgendaModal")
      agendaIssueValueField = agendaModal.find("#select-agenda-issue")
      agendaIssueSearchField = agendaModal.find("#agenda_agenda_issue_title")

      if( ((agendaIssueValueField.val() != "") && (agendaIssueSearchField.attr("new_issue") == "false")) || 
          ((agendaIssueValueField.val() == "") && (agendaIssueSearchField.attr("new_issue") == "true")) ){

        agendaIssueSearchField.val("").attr("new_issue", "false")
        agendaIssueValueField.val("")
      }
    }
  });

  function meetingValueAdded(values, field) {
    if(field == 'attendee'){
      targetButton = ".meeting-attendees-section #add-meeting-attendee"
    }else{
      targetButton = ".meeting-notifees-section #add-meeting-notifee"
    }

    $(targetButton).attr("data-id", values)[0].click()
  }

  function meetingValueRemoved(values, field) {
    if(field == 'attendee'){
      targetButton = ".meeting-attendees-section #remove-meeting-attendee"
    }else{
      targetButton = ".meeting-notifees-section #remove-meeting-notifee"
    }

    $(targetButton).attr("data-id", values)[0].click()
  }

});
