$(document).ready(function() {
  $(document).on('click',"#edit_favorite",function(event) {
    event.preventDefault();
    $("#favorite_form").toggle();
    $("#favorite").toggle();
    document.getElementById('issue_favorite').value =  $('#favorite')[0].innerHTML
  });

  $(document).on('click',"#close_favorite_form",function(event) {
    event.preventDefault();
    $("#favorite_form").toggle();
    $("#favorite").toggle();
    document.getElementById('issue_favorite').value =  $('#favorite')[0].innerHTML
  });

  $(document).on('click',"#edit_options",function(event) {
    event.preventDefault();
    $("#options_form").toggle();
    $("#options").toggle();
    document.getElementById('issue_options').value =  $('#options')[0].innerHTML
  });

  $(document).on('click',"#close_options_form",function(event) {
    event.preventDefault();
    $("#options_form").toggle();
    $("#options").toggle();
    document.getElementById('issue_options').value =  $('#options')[0].innerHTML
  });

  $(document).on('click',"#creation_date_edit",function(event) {
    event.preventDefault();
    $("#creation_date_form").toggle();
    $("#creation_date").toggle();
    document.getElementById('issue_creation_date').value =  $('#creation_date')[0].innerHTML
  });

  $(document).on('click',"#close_creation_date_form",function(event) {
    event.preventDefault();
    $("#creation_date_form").toggle();
    $("#creation_date").toggle();
    document.getElementById('issue_creation_date').value =  $('#creation_date')[0].innerHTML
  });

  $(document).on('click',"#scheduled_date_edit",function(event) {
    event.preventDefault();
    $("#scheduled_date_form").toggle();
    $("#scheduled_date").toggle();
    $("#scheduled_date_error").hide();
    document.getElementById('issue_scheduled_date').value =  $('#scheduled_date')[0].innerHTML
  });

  $(document).on('click',"#close_scheduled_date_form",function(event) {
    event.preventDefault();
    $("#scheduled_date_form").toggle();
    $("#scheduled_date").toggle();
    $("#scheduled_date_error").hide();
    document.getElementById('issue_scheduled_date').value =  $('#scheduled_date')[0].innerHTML
  });

  $(document).on('click',"#deadline_date_edit",function(event) {
    event.preventDefault();
    if( $("#scheduled_date_value").val() != ""){
      $("#deadline_date_form").toggle();
      $("#deadline_date").toggle();
      document.getElementById('issue_deadline_date').value =  $('#deadline_date')[0].innerHTML
    }else{
      $("#scheduled_date_error").show();
    }
  });

  $(document).on('click',"#close_deadline_date_form",function(event) {
    event.preventDefault();
    $("#deadline_date_form").toggle();
    $("#deadline_date").toggle();
    document.getElementById('issue_deadline_date').value =  $('#deadline_date')[0].innerHTML
  });

  $(document).on('click',"#resolution_date_edit",function(event) {
    event.preventDefault();
    $("#resolution_date_form").toggle();
    $("#resolution_date").toggle();
    document.getElementById('issue_resolution_date').value =  $('#resolution_date')[0].innerHTML
  });

  $(document).on('click',"#close_resolution_date_form",function(event) {
    event.preventDefault();
    $("#resolution_date_form").toggle();
    $("#resolution_date").toggle();
    document.getElementById('issue_resolution_date').value =  $('#resolution_date')[0].innerHTML
  });

  $('#exportDocumet').on('hidden.bs.modal', function (e) {
    $(this)
      .find("input,textarea,select")
         .val('')
         .end()
      .find("input[type=checkbox], input[type=radio]")
         .prop("checked", "")
         .end();
  })

});

$( document ).on('turbolinks:load', function() {
  if (window.location.hash && ($(window.location.hash).length > 0)) {
    top_scroll = $(window.location.hash).offset().top - 70;
    window.scrollTo(0,top_scroll);
  }
})