import ApplicationController from './application_controller'

export default class extends ApplicationController {
  connect () {
    super.connect()

    return new bootstrap.Tooltip(this.element, this.tooltipDefaultOptions)
  }

  get tooltipDefaultOptions() { 
    return {
      html: true,
      trigger: 'hover'
    }
  };
}
