import ApplicationController from './application_controller'

export default class extends ApplicationController {
  connect () {
    super.connect()
    // add your code here, if applicable
  }

  enter_new_deliverable(event){
    if(event.keyCode == 13){
      event.preventDefault()
      if(( event.target.value.replace('#', '') != '' ) && $("#deliverablesReferences .deliverable_link").length == 0){
        this.add_new_deliverable()
      }
    }
  }

  add_new_deliverable(){
    let new_deliverable_title = $(".todo_deliverables_form #todo_deliverables_field").val().replace("#",'').trim()
    if(new_deliverable_title != ''){
      this.append_selected_deliverable(new_deliverable_title, true)
    }
  }

  show_delete_dialog(event){
    $("#deliverableDestroy").modal('show')
  }

  add_existing_deliverable(event){
    this.append_selected_deliverable(event.target, false)
  }

  append_selected_deliverable(deliverable, is_new_deliverable = false){
    if(is_new_deliverable){
      let newDeliverableId = Math.random().toString(36).replace(/[^a-z]+/g, '').substr(2, 7);
      var selected_html_ele = `<li class="associated_fields list-group-item d-flex justify-content-between align-items-center new_todo_deliverable" id="${newDeliverableId}" new_deliverable_title="${deliverable}">
                            [${I18n.t('frontend.new')}]  ${deliverable} <button type="button" class="btn-close remove-selected-deliverable" data-controller="todos" data-action="click->todos#remove_new_deliverables"></button>
                          </li>`
      this.add_hidden_fields(newDeliverableId)
    }else{
      var deliverable_title = deliverable.getAttribute('deliverable_title')
      var selected_html_ele = `<li class="associated_fields list-group-item d-flex justify-content-between align-items-center" id="Deliverable-${deliverable.id}">
                            ${deliverable_title} <button type="button" class="btn-close remove-selected-deliverable" data-controller="todos" data-action="click->todos#remove_new_deliverables"></button>
                            </li>`
      this.add_fields_for_existing_deliverable(deliverable.id)
    }

    $("#selectedDeliverablesSection #selectedDeliverablesList").append(selected_html_ele)
    $(".todo_deliverables_form #todo_deliverables_field").val("")
    $(".todo_deliverables_form #deliverablesReferences").html('')

    $("#selectedDeliverablesSection #selectedDeliverablesList").scrollTop($("#selectedDeliverablesSection #selectedDeliverablesList")[0].scrollHeight);

  }

  add_fields_for_existing_deliverable(deliverable_id){
    var index = document.querySelectorAll('input[name*="existing"]').length
    var container = document.querySelector('.todo_deliverables_form');
    var hiddenField = document.createElement('input');
    hiddenField.type = 'hidden';
    hiddenField.value = deliverable_id
    hiddenField.name = `existing_deliverables[${index}]`
    container.appendChild(hiddenField);
    index = index + 1;
  }

  add_hidden_fields(newDeliverableId) {
    var container = document.querySelector('.todo_deliverables_form');
    if (container) {
      var hiddenFields = document.createElement('div');
      hiddenFields.className = "hidden-fields"
      hiddenFields.id = newDeliverableId
      let index = $('.hidden-fields').length
      container.querySelectorAll(".form-deliverable-fields").forEach((hiddenInput) => {
        let updatedName = this.getFieldName(hiddenInput.name, index)
        var hiddenField = document.createElement('input');
  
        hiddenField.type = 'hidden';
        hiddenField.value = hiddenInput.value
        hiddenField.name = updatedName
        hiddenFields.appendChild(hiddenField);
      });
      container.append(hiddenFields)
    }
  }
  
  getFieldName(paramsName, index) {
    if(paramsName.startsWith('condition')){
      var count = 0;
      var updatedParamsName = paramsName.replace(/\[0\]/g, function(match) {
        count++;
        return count === 2 ? `[${index}]` : match;
      });
    }else{
      var updatedParamsName = paramsName.replace(/\[\d+\]/, '[' + index + ']');
    }
    return updatedParamsName
  }

  remove_new_deliverables(event){
    let newDeliverableId = event.target.parentElement.id
    let hiddenFieldInputTag = document.querySelector(`div.hidden-fields#${newDeliverableId}`);
    hiddenFieldInputTag.remove()
    event.target.parentElement.remove()
  }

  remove_deliverables(event) {
    let deleteDocument = event.target.dataset.deleteDocument
    const deliverableId = event.target.dataset.id;
    const name = document.querySelector('.todo_deliverables_form input').name;
    const lastIndex = name.lastIndexOf('[');
    const container = document.querySelector('.todo_deliverables_form');
    const hiddenFields = document.createElement('div');
    hiddenFields.className = "hidden-fields-for-destroy";
    
    const destroyField1 = document.createElement('input');
    destroyField1.type = 'hidden';
    destroyField1.name = name.substring(0, lastIndex).replace(/\[0\]/g, `[${deliverableId}]`) + '[_destroy]';
    destroyField1.value = "1";
    hiddenFields.appendChild(destroyField1);
  
    const destroyField2 = document.createElement('input');
    destroyField2.type = 'hidden';
    destroyField2.name = name.replace(/\[0\]/g, `[${deliverableId}]`).replace(/\[[^\]]+\]$/, '[id]');
    destroyField2.value = deliverableId;
    hiddenFields.appendChild(destroyField2);
  
    if (event.target.dataset.todoId != null) {
      const destroyField3 = document.createElement('input');
      destroyField3.type = 'hidden';
      destroyField3.name = `condition[todos_attributes][${deliverableId}][id]`;
      destroyField3.value = event.target.dataset.todoId;
      hiddenFields.appendChild(destroyField3);
    }

    if(deleteDocument){
      const destroyField4 = document.createElement('input');
      destroyField4.type = 'hidden';
      destroyField4.name = name.replace(/\[0\]/g, `[${deliverableId}]`).replace(/\[[^\]]+\]$/, '[delete_document]');
      destroyField4.value = deleteDocument;
      hiddenFields.appendChild(destroyField4);
    }
  
    container.append(hiddenFields);
    $(`#Deliverable-${deliverableId}`).remove();
    $("#deliverableDestroy").modal("hide");
  }

  open_modal(event) {
      let deliverableId = event.target.dataset.id;
      let todoId = event.target.dataset.todoId;
      let modalUrl = `/todo/${todoId}/delete_deliverable_modal`;
      let modalContainer = $("#deleteDeliverableConfirmation");
    
      $.ajax({
        url: modalUrl,
        method: "GET",
        data: {
          deliverable_id: deliverableId
        },
        success: function(data) {
          modalContainer.innerHTML = data;
          $("#deliverableDestroy").modal("show");
        },
        error: function(data) {
          console.error("Error");
        }
      });
  }


  complete_checkbox_action(event) {
    let update_url = event.target.dataset.updateUrl

    $.ajax({
      url: update_url,
      method: "PUT",
      dataType : 'script'
    })
  }
}
