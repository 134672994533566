import consumer from "./consumer"

consumer.subscriptions.create("Noticed::NotificationChannel", {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  //commenting this because we are not using recieve live notification currently
  // received(data) {
  //   if(parseInt($(".selected-organization").first().attr("org_id")) == data.organization_id){
  //     $("#noNotifications").remove()
  //     if($(`#notificationSection #${data.user_notification_id}`).length == 0){
  //       $("#notificationSection div.card-body").prepend(data.data)
  //       $("#notificationDropdownMenuLink span.dot").removeClass("invisible")
  //     }
  //   }
  // }
});
