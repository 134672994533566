import ApplicationController from './application_controller'

/* This is the custom StimulusReflex controller for the Conditions Reflex.
 * Learn more at: https://docs.stimulusreflex.com
 */
export default class extends ApplicationController {

  connect () {
    super.connect()
    // add your code here, if applicable
  }

  display_reorder_icon(event){
    if (event.target.value == 'manual'){
      if(!event.target.classList.contains('display-reorder')){
        $("#Conditions #condition-reorder").attr('data-controller', 'position')
        $("#Conditions .reorder-icon").removeClass('invisible')
        event.target.classList.add('display-reorder')
      }
      else{
        $("#Conditions .reorder-icon").addClass('invisible')
        event.target.classList.remove('display-reorder')
      }
    }
    else{
      event.target.classList.remove('display-reorder')
      this.stimulate("ConditionsReflex#order", event.target)
    }
  }
  show_delete_dialog(event){
    $("#conditionDestroy").modal('show')
  }

  toggleProofMandatoryVisibility(event) {
    $("#asterisk_deliverable").toggleClass("hide");
    $("#mandatory_text_id").toggleClass("hide");
  }


}
