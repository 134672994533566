import ApplicationController from './application_controller'
export default class extends ApplicationController {
  connect () {
    super.connect()
  }

  removeField() {
    let parentEleSelector = `.${this.element.dataset['fieldSelector']}`;
    let isInfoField = this.element.dataset['fieldSelector'].includes('info-')

    $(this.element).tooltip('dispose')
    $(this.element).parents(parentEleSelector).remove();

    if(isInfoField){
      // set the position attribute when an info field is Added/Removed from Template
      $('#positionTemplateInfoFields').trigger('click')
    }
  }

  deleteAllInfoFields() {
    let confirmed = confirm("Are you sure?")

    if(confirmed){
      $("#options #available_infos_section")[0].innerHTML = ''
      $("#additional-infos-section .add-new-additional-info")[0].dataset.index = '1'
    }
  }

  deleteAllConditions() {
    let confirmed = confirm("Are you sure?")

    if(confirmed){
      $("#conditions-section #conditions-list")[0].innerHTML = ''
      $("#conditions-section #add-condition")[0].dataset.currentIndex = '0'
    }
  }

  deleteAllTodos() {
    let confirmed = confirm("Are you sure?")

    if(confirmed){
      $("#todos-section #todos-list")[0].innerHTML = ''
      $("#todos-section #add-todo")[0].dataset.currentIndex = '0'
    }
  }

  deleteAllDocuments() {
    let confirmed = confirm("Are you sure?")

    if(confirmed){
      $("#documents-section #documents-list")[0].innerHTML = ''
      $("#documents-section #add-document")[0].dataset.currentIndex = '0'
    }
  }

}
