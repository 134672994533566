import ApplicationController from './application_controller'
export default class extends ApplicationController {

  static targets = [ "commentContent", "timelineCommentContent", "notes"]

  showGridView() {
    document.getElementById("GridView").style.display=""
    document.getElementById("ListView").style.display="none"
    document.getElementById("DownloadBtn").style.display="none"
    document.getElementById("PrintBtn").style.display="none"
  }

  showListView() {
    document.getElementById("ListView").style.display=""
    document.getElementById("DownloadBtn").style.display=""
    document.getElementById("PrintBtn").style.display=""
    document.getElementById("GridView").style.display="none"
  }

  printDiv() {
    var divContents = document.getElementById("ListView").innerHTML;
    var a = window.open('', '', 'height=700, width=700');
    a.document.write('<html>');
    a.document.write(`<body > <h1> ${I18n.t('frontend.headings.comments')} </h1> <br>`);
    a.document.write(divContents);
    a.document.write('</body></html>');
    a.document.close();
    a.print();
  }

  add_object_comment(event) {
    event.target.dataset.comment_content = this.commentContentTarget.value
    event.target.dataset.is_info_note_comment = this.notesTarget.checked    
    event.target.dataset.main_object_type = $("#objectCommentsModal #mainCommentObject").attr("main_object_type")
    event.target.dataset.main_object_id = $("#objectCommentsModal #mainCommentObject").attr("main_object_id")
    this.stimulate("Comments#add_object_comment", event.target)
  }

  add_timeline_object_comment(event) {
    event.target.dataset.comment_content = this.timelineCommentContentTarget.value
    event.target.dataset.is_info_note_comment = this.notesTarget.checked
    if(event.target.dataset.commentable_type == "Comment"){
      let main_cmt_obj = $(this.element).parents(".timelineObjectAccordionItem").find(".timelineMainCmtObj")
      event.target.dataset.main_object_type = main_cmt_obj.attr("main_object_type")
      event.target.dataset.main_object_id = main_cmt_obj.attr("main_object_id")
    }
    this.stimulate("Comments#add_timeline_object_comment", event.target)
  }

  delete_obj_comment_from_modal(event) {
    event.preventDefault();

    const status = confirm(I18n.t('frontend.are_you_sure'))
    if(!status){
      return false;
    }else{
      const el = event.currentTarget
      const id = el.getAttribute("data-id");
      event.target.dataset.main_object_type = $("#mainCommentObject").attr("main_object_type")
      event.target.dataset.main_object_id = $("#mainCommentObject").attr("main_object_id")
      this.stimulate("CommentsReflex#delete_obj_comment_from_modal")
    }
  }  

  delete_obj_comment(event) {
    // TODO: Remove this method, as we are not allowing the user to delete their comments from timeline or Modal.
    event.target.dataset.main_object_type = $("#objectCommentsModal #mainCommentObject").attr("main_object_type")
    event.target.dataset.main_object_id = $("#objectCommentsModal #mainCommentObject").attr("main_object_id")
    this.stimulate("Comments#delete_obj_comment", event.target)
  }

  addObjectCommentSuccess(element) {
    this.commentContentTarget.value = ""
    $(this.commentContentTarget).trigger('focus')
  }

  addTimelineObjectCommentSuccess(element) {
    this.timelineCommentContentTarget.value = ""
    $(this.timelineCommentContentTarget).trigger('focus')
  }

  show_hide_replies(event) {
    let cmnt_id = this.element.dataset.commentable_id
    if($(`#comments_list_on_${cmnt_id}`).hasClass('invisible')){
      this.element.innerText = I18n.t('frontend.comments.hide_replies')
      this.stimulate("CommentsReflex#show_comment_comments")
    }else{
      this.element.innerText = I18n.t('frontend.comments.show_replies')
      $(`#comments_list_on_${cmnt_id}`).addClass('invisible')
    }
  }

  show_hide_timeline_replies(event) {
    let cmnt_id = this.element.dataset.commentable_id
    let is_reply_fl_displayed = $(`#timeline_comment_${cmnt_id}_row .comment-field-element`).length > 0
    if($(`#timeline_comments_list_on_${cmnt_id}`).hasClass('invisible') || 
        $(`#timeline_comments_list_on_${cmnt_id} .commentTimelineList`).attr('list_displayed') == "false"){
      this.element.innerText = I18n.t('frontend.comments.hide_replies')
      this.element.dataset.is_reply_fl_displayed = is_reply_fl_displayed
      this.stimulate("CommentsReflex#show_timeline_comment_comments")
    }else{
      this.element.innerText = I18n.t('frontend.comments.show_replies')

      if(is_reply_fl_displayed){
        $(`#timeline_comments_list_on_${cmnt_id} li.comment-record`).remove()
        $(`#timeline_comments_list_on_${cmnt_id} .commentTimelineList`).attr('list_displayed', "false")
      }else{
        $(`#timeline_comment_${cmnt_id}_row .auther-comment`).removeClass("replies_visible")
        $(`#timeline_comments_list_on_${cmnt_id} `).addClass('invisible').html("")
      }
    }
  }

  cancel_obj_comment(event) {
    // let cmt_id = $(this.element).parents(".comment-record").attr('c-id')
    // $(`#comment_${cmt_id}_info .comment-reply`).removeClass('invisible')

    // if($(`#comments_list_on_${cmt_id}`).children().length > 0){       
    //   if($(`#comments_list_on_${cmt_id}`).children()[0].childElementCount > 0)
    //   {
    //     $(`#comment_${cmt_id}_info .deleteComment`).addClass('invisible')
    //   }
    // }else{
    //   $(`#comment_${cmt_id}_info .deleteComment`).removeClass('invisible')
    // }
    // $(`#add_comment_on_${cmt_id}`).html("").addClass('invisible')

    $(this.element).toggleClass('invisible')
  }

  cancel_timeline_obj_comment(event) {
    let cmt_id = $(this.element).parents(".comment-record").attr('c-id')
    if($(`#timeline_comments_list_on_${cmt_id} .commentTimelineList`).attr('list_displayed') == "true"){
      $(`#timeline_comments_list_on_${cmt_id} li.comment-field-element`).remove()
    }else{
      $(`#timeline_comments_list_on_${cmt_id}`).addClass('invisible').html("")
      $(`#timeline_comment_${cmt_id}_row .auther-comment`).removeClass("replies_visible")
    }
    
    if($(`#timeline_comments_list_on_${cmt_id}`).children().length > 0){
      if($(`#timeline_comments_list_on_${cmt_id}`).children()[0].childElementCount > 0){
        $(`#timeline_comment_${cmt_id}_row li.deleteTimelineComment`).addClass('invisible')
      }
    }else{
      $(`#timeline_comment_${cmt_id}_row a.deleteTimelineComment`).removeClass('invisible')
    }
    $(`#timeline_comment_${cmt_id}_row .comment-reply`).removeClass('invisible')
  }

  update_obj_comment(event){
    event.target.dataset.comment_content = this.commentContentTarget.value
    event.target.dataset.is_info_note_comment = this.notesTarget.checked
    this.stimulate("Comments#update_obj_comment", event.target)
  }

  update_timeline_obj_comment(event){
    event.target.dataset.comment_content = this.timelineCommentContentTarget.value
    event.target.dataset.is_info_note_comment = this.notesTarget.checked
    this.stimulate("Comments#update_timeline_obj_comment", event.target)
  }

  show_comment_button_on_timeline_comment(event){
    let cmnt_id = this.element.dataset.commentable_id
    let is_replies_list_displayed = $(`#timeline_comments_list_on_${cmnt_id} .commentTimelineList`).attr('list_displayed') == "true"
    this.element.dataset.is_replies_list_displayed = is_replies_list_displayed
    this.stimulate("Comments#show_comment_button_on_timeline_comment")
  }

  delete_timeline_obj_comment_from_modal(event){
    event.preventDefault();
    const status = confirm(I18n.t('frontend.are_you_sure'))
    if(!status ){
      return false;
    }else{
      const el = event.currentTarget
      const id = el.getAttribute("data-id");
      this.stimulate("CommentsReflex#delete_timeline_obj_comment_from_modal")
    }    
  }
}
 