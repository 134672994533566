$(document).on('turbolinks:load',function() {
  $('.list-group-item').click(function(){
    localStorage.removeItem('meeting_id');
  })

  $('.btn-toggle').click(function(){
    $('.sidebar').toggleClass('open')
  })

  $(document).on('click','#global_search_list li',function(){
  	var dataTarget = $(this).attr('data-name')
  	localStorage.setItem('search-active-tab', dataTarget)
  })

  const target = document.querySelector('#global_search')

  document.addEventListener('click', (event) => {
    const withinBoundaries = event.composedPath().includes(target)
    if (!withinBoundaries) {
      target.innerText = ""
    }
  });

});
