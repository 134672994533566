// Class to clean 'pane' (to identify the model of sidepanel record) and specific model
// record id attribute from URL.
// NOTE: This is called  after closing the sidepanel for New/Show/Edit of model record,
//       when the above attributes are available in the URL, they should be removed
//       before reloading the page. As if the above attributes remain in the URL
//       it will keep opening the sidepanel after page renders.

function IndexUrlCleaner({currentLocation = null, scrollIdentifier = null, paramName = null}) {
  this.currentLocation = currentLocation;
  this.scrollIdentifier = scrollIdentifier;
  this.paramName = paramName;
}

IndexUrlCleaner.prototype.getUrl = function(){
  let url = (this.currentLocation.origin + this.currentLocation.pathname)
  let filterParams = this.currentLocation.search.slice(1).split('&')
  let invalidParams = this.paramName

  filterParams = filterParams.filter(function(item){
    if (item.includes('pane') || item.includes(invalidParams)){
      return false;
    } else {
      return true;
    }
  })

  if(filterParams.length > 0){ url = url + '?' + filterParams.join('&') }
  if(this.scrollIdentifier != null){ url = url + '#' + this.scrollIdentifier }

  return url
}

export default IndexUrlCleaner