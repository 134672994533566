import ApplicationController from './application_controller'
export default class extends ApplicationController {

  connect() {
    super.connect()
  }

  show_user_notifications(event){
    if($(this.element).attr('aria-expanded') == 'true'){
      this.stimulate("Home#show_user_notifications")
    }
  }

  focusin_global_search(){
    $(event.target.parentElement).addClass('focused')
  }

  focusout_global_search(){
    if(event.target.value.trim() == ''){
      $(event.target.parentElement).removeClass('focused')
    }
  }


}
