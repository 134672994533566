import ApplicationController from './application_controller'

export default class extends ApplicationController {

  static targets = [ "statusChangeReason", "revitaliseType", "revitaliseDate", 'finalDecision', 'scheduledDate', 'deadlineDate']

  make_issue_status_editable(event){
    let status = document.getElementById(this.element.getAttribute("issue_id")+'status')
    let status_badge = $("#status-badge")[0]

    status.disabled = false
    status.classList.add('form-select');
    status.classList.remove('field-style', 'invisible');
    status_badge.classList.add('invisible')
    // status.style.appearance = 'menu'
  }

  change_accordion(event) {
    if($(this.element).attr('aria-expanded') == 'true'){
      Turbolinks.clearCache()
      Turbolinks.visit(this.element.dataset.redirectUrl, { action: 'replace' })
    }else{
      Turbolinks.visit(`/issues/${this.element.dataset.issueId}/overview_info`, { action: 'replace' })
    }
  }

  check_valid_title(event){
    const titles = [];
    $("#additional_info_fields .info-title").not($(this.element)).each(function() {
      titles.push($(this).val());
    });
    if (titles.includes(this.element.value)){
      let validation_msg = I18n.t('frontend.issues.validations.title_unavailable')

      $(this.element.parentElement).find('.title-validity-status').text(validation_msg)
      $('.save-addi-info-button').attr('disabled', true)
    } else {
      let validation_msg = I18n.t('frontend.issues.validations.title_available')

      $(this.element.parentElement).find('.title-validity-status').text(validation_msg)
      $('.save-addi-info-button').attr('disabled', false)
    }
  }

  check_valid_reason(event){
    if (this.statusChangeReasonTarget.value == '')
    {
      $('.title-validity-status').text(I18n.t('frontend.issues.validations.missing_reason'))
    }else{
      $('.title-validity-status').text("")
      event.target.dataset.reason = this.statusChangeReasonTarget.value
      $(event.target).closest('.modal').modal('hide')
      this.stimulate('IssuesReflex#update_no_final', event.target)
    }
  }

  check_valid_reason_for_no_follow_up(event){
    if (this.revitaliseTypeTargets[1].checked == true && this.revitaliseDateTarget.value == '') {
      $('.revitalise-date').text(I18n.t('frontend.issues.validations.missing_date'))
    }else if (this.revitaliseTypeTargets[0].checked == true && this.statusChangeReasonTarget.value == '') {
      $('.title-validity-status').text(I18n.t('frontend.issues.validations.missing_reason'))
    }else{
      
      $('.title-validity-status').text('')
      $('.revitalise-date').text('')
      $(event.target).closest('.modal').modal('hide')
      
      let type = this.revitaliseTypeTargets[1].checked == true ? 'date' : 'condition'
      event.target.dataset.reason = this.statusChangeReasonTarget.value
      event.target.dataset.revitaliseDate = this.revitaliseDateTarget.value
      event.target.dataset.revitaliseType = type
      this.stimulate('IssuesReflex#update_no_follow_up', event.target)
    }
  }

  toggle_unfinalized_secion(event) {
    if (this.finalDecisionTargets[0].checked) {
      $('#final-decision-justify').hide()
    } else if (this.finalDecisionTargets[1].checked) {
      $('#final-decision-justify').show()
    }
  }

  check_valid_reason_for_final_review(event){
    
    if (this.statusChangeReasonTarget.value == '')
    {
      $('.title-validity-status').text(I18n.t('frontend.issues.validations.missing_reason'))
    }else{
      if (this.finalDecisionTargets[1].checked == true){
        if (this.revitaliseTypeTargets[1].checked == true && this.revitaliseDateTarget.value == '') {
          $('.revitalise-date').text(I18n.t('frontend.issues.validations.missing_date'))
        }else{
          $('.title-validity-status').text('')
          $('.revitalise-date').text('')
          $(event.target).closest('.modal').modal('hide')

          let final_deci = this.finalDecisionTargets[0].checked == true ? true : false
          
          let type = this.revitaliseTypeTargets[1].checked == true ? 'date' : 'condition'
          event.target.dataset.reason = this.statusChangeReasonTarget.value
          event.target.dataset.revitaliseDate = this.revitaliseDateTarget.value
          event.target.dataset.revitaliseType = type
          event.target.dataset.finalDecision = final_deci
          let path = window.location.href.split("todos")[0] + "/overview"
          Turbolinks.visit(path)
          this.stimulate('IssuesReflex#update_final_review', event.target)
        }
      }else{
        $('.title-validity-status').text('')
        $('.revitalise-date').text('')
        let final_deci = this.finalDecisionTargets[0].checked == true ? true : false
        
        event.target.dataset.reason = this.statusChangeReasonTarget.value
        event.target.dataset.finalDecision = final_deci
        let path = window.location.href.split("todos")[0] + "/overview"
        Turbolinks.visit(path)
        this.stimulate('IssuesReflex#update_final_review', event.target)
      }
    }
    
  }

  highlight_unfilled_data(event) {
    const errorCss = { "border": "1px solid #d84a38" }
    const validCss = { "border": "1px solid #ced4da" }

    const validationElements = {
      'data-description': $('#edit-description'),
      'data-favorite': $('#edit-favorite'),
      'data-options': $('#edit-option'),
      'data-group-approver': $('#issue_approver_id')
    }

    for (let key in validationElements) {
      let value = event.currentTarget.getAttribute(key)

      if (value)
        validationElements[key].css(validCss)
      else
        validationElements[key].css(errorCss)
    }
  }

  delete_aditional_info_field(event) {
    let info_parent_div = $(event.target).parents('.issue_additional_info')
    if(info_parent_div.hasClass('new_info_field')){
      info_parent_div.remove()
    }else{
      info_parent_div.find('.info-destroyed').val('true')
      info_parent_div.addClass('d-none')
    }

    // set the position attribute when an info field is Added/Removed from Fredfile
    $('#positionIssueInfoFields').trigger('click')
  }

  save_scheduled_or_deadline_date(event){
    event.preventDefault();
    let type = event.target.dataset.type;
    this.date = this[type + "DateTarget"].value;
    this.stimulate('IssuesReflex#save_scheduled_or_deadline_date', type, this.date);
  }

  show_confirm_submit_form(event){
    if($('#issue_attendee_removal_confirmed').val() == 'false'){
      event.preventDefault();
      var issues_form = $(event.target)
      let updated_attendees = issues_form.find('#select-issue-stakeholders')[0].tomselect.items

      $.ajax({
        url: `confirm_attendee_removal`,
        method: "GET",
        dataType: 'json',
        data: { updated_attendees: updated_attendees },
        success: function(data){
          if(data['confirmation_needed']){
            $('#attendee_removal').html(data['confirmation_html'])
            $('#confirmAttendeeRemovalModal').modal('show')
          }else {
            $('#issue_attendee_removal_confirmed').val(true)
            issues_form.submit()
          }
        }
      })
    }
  }

  cancel_removal_confirmation(event){
    $('#issues_attendee_removal_confirmed').val(false)
    $('#confirmAttendeeRemovalModal').modal('hide')

    // reset the Stakeholders field
    let stakeholder_field = $('#select-issue-stakeholders')[0].tomselect
    let previous_attendees = event.target.dataset.selectedAttendees.split(',')
    stakeholder_field.setValue(previous_attendees, true)
  }

  continue_removal_confirmation(event){
    $('#issues_attendee_removal_confirmed').val(true)
    $('.edit_issue').submit();
  }

  visibility_changed(event){
    let selected_options = {}
    selected_options['visibility'] = event.target.value
    selected_options['group_id'] = $('#issue_group_id').val()

    if(event.target.value == 'private'){
      $('#issue_group_id').prop('required', false)
      $('#responsible_group_component span.text-danger').text('')
      $('#responsible_group_component .attention_note').removeClass('invisible')
    }else{
      selected_options['is_edit_form'] = event.target.dataset['issueId'].length > 0
      $('#issue_group_id').prop('required', selected_options['is_edit_form'])   // if edit form, then field should be required.
      $('#responsible_group_component span.text-danger').text( (selected_options['is_edit_form'] ? '*' : '') )
      $('#responsible_group_component .attention_note').addClass('invisible')
    }

    this.stimulate('IssuesReflex#visibility_changed', selected_options);
  }

  approver_list(event){
    let selected_options = {}
    selected_options['visibility'] = $('#issue_visibility').val()
    selected_options['group_id'] = event.target.value
    selected_options['is_edit_form'] = event.target.dataset['issueId'].length > 0

    this.stimulate('IssuesReflex#approver_list', selected_options);
  }
}
