import { Application } from 'stimulus'
import { definitionsFromContext } from 'stimulus/webpack-helpers'
import StimulusReflex from 'stimulus_reflex'
import consumer from '../channels/consumer'
import controller from '../controllers/application_controller'
import debounced from 'debounced'
import Popover from "stimulus-popover"
debounced.initialize()

const app = require.context('controllers', true, /_controller\.js$/)

const application = Application.start()
application.register("popover", Popover)
application.load(definitionsFromContext(app))
application.consumer = consumer

StimulusReflex.initialize(application, { controller, debug: true, isolate: true })

application.load(definitionsFromContext(app))
import Flatpickr from 'stimulus-flatpickr'
require("flatpickr/dist/flatpickr.css")
require("dropzone/dist/dropzone.css")

import TomSelect from 'tom-select'
require("tom-select/dist/css/tom-select.bootstrap5")
application.register('tom-select', TomSelect)

import ApexCharts from 'apexcharts'
require("apexcharts/dist/apexcharts.js")
application.register('apexcharts', ApexCharts)