import ApplicationController from './application_controller'

// Controller to open specific sidepanel after page renders,
export default class extends ApplicationController {

  connect(){
    super.connect();

    this.element.click();
  }
}
