window.jQuery = $;
window.$ = $;
window.TomSelect = TomSelect
window.ApexCharts = ApexCharts
window.Tour = Tour
window.IndexUrlCleaner = IndexUrlCleaner

require('jquery')
require("popper.js")
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require("@fortawesome/fontawesome-free/js/all")
require("@fortawesome/fontawesome-free/css/all")
require("packs/custom")
require("packs/sidebar")
require("packs/issue_dependencies")
require("packs/issues_form")
require("packs/todos")
require("packs/deliverables")
require("packs/meetings")
require("packs/comments")
require("packs/groups")
require("packs/header")
require("packs/conditions")
require("packs/templates")
require("i18n/translations")
require("lightbox2/dist/js/lightbox-plus-jquery.min.js")
require("lightbox2/dist/css/lightbox.css")

// import 'bootstrap/dist/js/bootstrap'
import "bootstrap/dist/css/bootstrap";

import Flatpickr from 'stimulus-flatpickr'
require("flatpickr/dist/flatpickr.css")

import "controllers"
import TomSelect from 'tom-select';
import ApexCharts from 'apexcharts';
import IndexUrlCleaner from "./index_url_cleaner";

import('stylesheets/application.scss');
window.bootstrap = require('bootstrap/dist/js/bootstrap.bundle.js');
import Tour from "./plugins/bs5-intro-tour"

import I18n from 'packs/i18n-language'

// Fix for Safari dropping Action Cable connection Issue.
const originalWebSocketClose = WebSocket.prototype.close
WebSocket.prototype.close = function () {
  if (this.readyState != WebSocket.CONNECTING) {
    originalWebSocketClose.apply(this, arguments)
  }
}

// Custom event to hide flash message alert when displayed from Reflex.
$(document).on('hide_flash_message', '#flash_message_block', function(event){
  if($('.flash_message').length > 0) {
    setTimeout(function(){
      let flash_message_node = $(document).find('.flash_message')
      let bsAlertNode = new bootstrap.Alert(flash_message_node)
      bsAlertNode.close()
    }, 3000)
  }
});

