$(document).ready(function() {
	$(document).on('change',".comment-on",function(event){
		
		jQuery.ajax({
		 url: '/get_option_for_comment',
		 type: 'get',
		 contentType: 'application/json',
		 data: ({ selected_model: this.value,
							issue_id : $("#comment_issue_id").val() }),
		 success: function (result) {	}
		 
	 });
	$("#comment_on_error").hide();
	if(this.value == "Issue"){
	  document.getElementById("comment_commentable_id").value = $("#comment_issue_id").val()
	}
	});

	$(document).on('click',".save_comment",function(event) {
		if ($(".comment-on").val()!="Issue" && $('#comment_option').val() == null && $("#from_comment").val() != ""){
			event.preventDefault();
			$("#comment_on_error").show();
		}
  });

	$(document).on('click',".scrollToReply", function(event) {
		id = `comment_${this.id}_row`
		document.getElementById(id).scrollIntoView({
			behavior: 'smooth'
		});
  });
});
