import ApplicationController from './application_controller'
export default class extends ApplicationController {

  switchPlan(event){
    let currentPlan = $('#switchPlanForm #current_price_id').val();
    let selectedPlan = this.element.value

    if(currentPlan == selectedPlan){
      $('#switchPlanForm #switchPlanSubmitBtn').prop('disabled', true)
    }else{
      $('#switchPlanForm #switchPlanSubmitBtn').prop('disabled', false)
    }
  }

  confirmPlanChange(event){
    let current_plan_on_trialing = $('#switchPlanForm #current_plan_on_trialing').val()

    if(current_plan_on_trialing == 'true'){
      this.element.innerText = I18n.t('frontend.saving')
      this.element.disabled = true
      $('#switchPlanForm').submit();
    }else{
      let selectedPlanInterval = $('#switchPlanForm .plan-price:checked').attr('data-plan-interval')
      let selectedPlanPrice = parseInt($('#switchPlanForm .plan-price:checked').attr('data-plan-price'))
      let active_membership_count = parseInt($('#switchPlanForm #active_membership_count').val())
      let confirmationMsg = ''
      if(selectedPlanInterval == 'month'){
        confirmationMsg = I18n.t('frontend.stripe.plan_changed_to_monthly')
      }else if(selectedPlanInterval == 'year'){
        confirmationMsg = I18n.t('frontend.stripe.plan_changed_to_yearly', { total_price: (selectedPlanPrice * active_membership_count), member_count: active_membership_count } )
      }
      $('#switchPlanConfirmationModal #confirmationMsg')[0].innerText = confirmationMsg
      $('#switchPlanConfirmationModal').modal('show')
    }
  }

  proceedPlanChange(event){
    this.element.innerText = I18n.t('frontend.saving')
    this.element.disabled = true
    $('#switchPlanForm').submit();
  }

}
