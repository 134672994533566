import ApplicationController from "./application_controller";

export default class extends ApplicationController {

  connect(){
    super.connect()
  }

  dropAdded(){
    let fileNameElement = $(this.element).find('.addedFileName')[0]
    fileNameElement.innerText = event.target.value.split('\\').pop()
  }

}
