$(document).ready(function() {

  $(document).on('click',".remove_template_row",function() {
    $(this).closest('.template_row').remove();
  });

  $(document).on('click', '.condition_document_yes', function(){      
    $(this).closest('.condition_doc').find('.textboxes').show();
  })
  $(document).on('click', '.condition_document_no', function(){      
    $(this).closest('.condition_doc').find('.textboxes').hide();
  })
});

$(document).on('click',".template_temp",function(event) {
  addtemplateIssue = $(this).parents("#templates_form")
  addtemplateIssue.find("#select-issue-template").val(this.getAttribute('value'))
  addtemplateIssue.find("#issue_template_id").val(this.innerText).attr("new_template", "false")
  addtemplateIssue.find("#search_templates").css("display", "none")
  addtemplateIssue.find("#template_list").remove()
  addtemplateIssue.find("#issue_form_search_templates").html('')
});

$(document).on('focus',"#addIssue #issue_template_id",function(event) {
  $(this).parents("#addIssue").find("#search_templates").css("display", "block")
});

$(document).on('keydown',"#addIssue #issue_template_id",function(event) {
  if(event.key == "Backspace"){
    templateissue = $(this).parents("#addIssue")
    templateIssueValueField = templateissue.find("#select-issue-template")
    templateIssueSearchField = templateissue.find("#issue_template_id")

    if( ((templateIssueValueField.val() != "") && (templateIssueSearchField.attr("new_template") == "false")) || 
        ((templateIssueValueField.val() == "") && (templateIssueSearchField.attr("new_template") == "true")) ){

      templateIssueSearchField.val("").attr("new_template", "false")
      templateIssueValueField.val("")
    }
  }
});


