
$(document).ready(function() {
  $(document).on('click',"#deliverable_url", function(event){
    if(window.location.href == event.target.href){
      return false
    } 
  });
  
  $(document).on('hidden.bs.offcanvas',"#offcanvasTodo",function(event){
    let urlCleaner = new IndexUrlCleaner({
                      currentLocation: window.location,
                      scrollIdentifier: event.target.children[0].id,
                      paramName: 'todo_id'
                    })

    Turbolinks.visit(urlCleaner.getUrl())
  })
});
