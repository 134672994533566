import ApplicationController from "./application_controller";
import { DirectUpload } from "@rails/activestorage";
import Dropzone from "dropzone";
import Rails from "@rails/ujs"
import heic2any from "heic2any";

export default class extends ApplicationController {


  
  static values = {
    url: String,
    formSelector: String,
    uploadFieldName: String,
    fileName: String,
    recordIdentifier: String
  };

  static targets = [ "album" ];

  connect() {


    super.connect();

    const files_array = [];

    this.dropzone = new Dropzone(this.albumTarget, {
      autoQueue: false,
      url: this.urlValue,
      acceptedFiles: ".jpg, .jpeg, .png, .heic, .heif",
      clickable: [ this.element.dataset.clickableSelector ],
      uploadMultiple: true,
      maxFiles: 100,
      addRemoveLinks: true,

      init: function () {
        var myDropzone = this;
        const thumbnailUrls = JSON.parse(this.element.dataset.files);
        
        if (thumbnailUrls) {

          for (var i = 0; i < thumbnailUrls.length; i++) {
            var mockFile = { 
              name: thumbnailUrls[i].filename, 
              size: thumbnailUrls[i].size, 
              status: Dropzone.ADDED, 
              url: thumbnailUrls[i].url 
            };

            // Call the default addedfile event handler
            myDropzone.emit("addedfile", mockFile);
            const remove_container = myDropzone.previewsContainer

            const link = $(remove_container).find("a[class='dz-remove']").last()

            link.text(I18n.t('frontend.deliverables.remove_link_text'))

            link.attr('data-id', thumbnailUrls[i].signed_id)

            // And optionally show the thumbnail of the file:
            myDropzone.emit("thumbnail", mockFile, thumbnailUrls[i].url);

            myDropzone.files.push(mockFile);
          }
        }
      },
        

      addedfiles: files => {
        event.preventDefault();
        //const that = this
        Array.from(files).forEach(async file => {
          if (file.type == 'image/heif') {
            let blobURL = URL.createObjectURL(file);
            const convertor = new HeicConvertor(blobURL)
            let blob = await convertor.process(blobURL)
            let new_file = new File([blob], file.name.replace('.heic', '.png'), { type: 'image/png' });
            file = new_file;
          }
          const uploader = new Uploader(file, this, this.recordIdentifierValue);

          uploader.process((error, blob) => {
            if (error) {
              // TODO: Handle the error
              console.error("Error")
            } else {
              const form = this.form;

              const remove_container = file.previewElement

              const link = $(remove_container).find("a[class='dz-remove']")
              link.attr('data-id', blob.signed_id)

              link.text(I18n.t('frontend.deliverables.remove_link_text'))

              const hiddenFileName = this.hiddenFileNameField
              hiddenFileName.value = blob.filename;

              const hiddenFileField = this.hiddenFileField;
              hiddenFileField.setAttribute("type", "hidden");
              files_array.push(blob.signed_id)
              hiddenFileField.setAttribute("value", files_array);
              
              if(this.canSubmitForm) { 
                this.formSubmitFormat == 'JS' ? Rails.fire(form, 'submit') : this.form.submit();
              }
            }
          });

          if(this.customHoverOverlay) { this.deactivate(); }
        });
      },

      removedfile: function(file) {
        if(file.status == "added"){
          $.ajax({
            url: `/delete_album_file/${file._removeLink.dataset.id}`,
            method: "DELETE",
            success: function(data){ console.log("success") },
            error: function(data) { console.error("Error")}
          })
        } else{
          var new_array = []
          new_array = files_array.filter(e => e !== file._removeLink.dataset.id)
          const hiddenFileField = this.hiddenFileInput;
          $(`input[name='deliverable[album_files][]']`).val(new_array)
        }
        file.previewElement.remove();
      }
    });

   
  }
  disconnect() {
    this.dropzone.destroy();
  }

  // to activate dropzone overlay
  activate(e) {
    this.element.classList.add("opacity-0")
    this.element.parentElement.querySelector('.drag-hover-message').classList.remove('opacity-0') 
  }

  // to deactivate dropzone overlay
  deactivate(e) {
    this.element.classList.remove("opacity-0")
    this.element.parentElement.querySelector('.drag-hover-message').classList.add('opacity-0')
  }


  get parentElementSelector() { return this.element.dataset.parentElementSelector }

  get form() { return document.querySelector(`${this.parentElementSelector} ${this.formSelectorValue}`) }

  get hiddenFileField() { return this.form.querySelector(`input[name='${this.uploadFieldNameValue}']`) }

  get hiddenFileNameField() { return this.form.querySelector(`input[name='${this.fileNameValue}']`) }

  get canSubmitForm() { return this.element.dataset.canSubmitForm == "true" }

  get needCustomProgressBar() { return (this.customProgressBarSection != false) }

  get formSubmitFormat() { 
    let format = 'HTML'
    if(this.canSubmitForm && this.element.dataset.formSubmitFormat == 'JS'){
      format = 'JS'
    }

    return format
  }

  get customProgressBarSection() { 
    let progressBar = false
    let prValue = this.element.dataset.addCustomProgressBar
    if(prValue != undefined && prValue != 'false'){
      progressBar = `${this.parentElementSelector} ${prValue}`
    }
    return progressBar;
  }

  get customHoverOverlay() { 
    let overlayStatus = false;
    if(this.element.dataset.customHoverOverlay == 'true') { overlayStatus = true; }
    return overlayStatus;
  }
}

class HeicConvertor {
  constructor(blobURL) {
    this.blobURL = blobURL;
  }

  process(blob) {
    return new Promise((resolve, reject) => {
      fetch(blob)
        .then((res) => res.blob())
        .then((blob) =>
          heic2any({
            blob,
            quality: 0.1,
            toType: 'image/png',
          })
        )
        .then((conversionResult) => {
          resolve(conversionResult)
        })
        .catch((e) => {
          console.log(e);
          reject(e);
        });
    });
  }
}

// This class wraps the feature for DirectUpload
class Uploader {

  constructor(file, source, uploadedRecordId) {
    this.upload = new DirectUpload(file, source.urlValue, this);
    this.uploadedRecordId = uploadedRecordId
    this.source = source
    this.file = file
  }

  process(callback) {
    this.upload.create(callback);
    if(this.source.needCustomProgressBar) { this.addProgressBar(); }
  }

  directUploadWillStoreFileWithXHR(request) {
    if(this.source.needCustomProgressBar){
      request.upload.addEventListener("progress", event =>
        this.directUploadDidProgress(event)
      );
    }
  }

  addProgressBar(){
    const uploadSection = document.querySelector(this.source.customProgressBarSection)
    if(this.source.customHoverOverlay) { uploadSection.querySelector('.upload-doc-button').classList.add('invisible') }

    uploadSection.querySelector(`${this.source.parentElementSelector} #direct-upload-${this.uploadedRecordId}`)?.remove()
    uploadSection.innerHTML = uploadSection.innerHTML + `<div class="d-flex flex-row" id="direct-upload-${this.uploadedRecordId}">
                                                          <div class="direct-upload text-truncate d-inline-block" style="width:85%">
                                                            <div id="direct-upload-progress-${this.uploadedRecordId}" class="direct-upload__progress" style="width: 0%"></div>
                                                            <span class="direct-upload__filename">${this.file.name}</span>
                                                          </div>
                                                          <span class="ms-1" id="direct-upload__percentage-${this.uploadedRecordId}"> 0% </span>
                                                        </div>`
  }

  directUploadDidProgress(event) {
    const progress = Math.round((event.loaded / event.total) * 100);
    const progressElement = document.querySelector(`${this.source.parentElementSelector} #direct-upload-progress-${this.uploadedRecordId}`)
    progressElement.style.width = `${progress}%`
    if(progress == 100){
      document.querySelector(`#direct-upload__percentage-${this.uploadedRecordId}`).innerHTML = `<i class="fas fa-check text-green"></i>`
    }else{
      document.querySelector(`#direct-upload__percentage-${this.uploadedRecordId}`).innerText = `${progress}%`
    }
  }

}