import ApplicationController from './application_controller'
import { Calendar } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';

import calendarEnLocale from '@fullcalendar/core/locales/en-gb';
import calendarDeLocale from '@fullcalendar/core/locales/de';

export default class extends ApplicationController {
  connect() {
    const calendarEl = this.element;
    const dateChangeButton = document.getElementById('date-change-button');
    const dateString = dateChangeButton.getAttribute('data-dates');
    const openDateCalendar = dateChangeButton.getAttribute('data-selected-date');
    const eventDates = JSON.parse(dateString.replace(/nil/g, 'null'));

    const events = eventDates.map(date => {
      return {
        title: '●',
        start: date
      };
    });

    var calendar = new Calendar(calendarEl, {
      plugins: [ dayGridPlugin, interactionPlugin ],
      height: 380,
      selectable: true,
      eventColor: 'white',
      eventTextColor: 'black',
      locales: [calendarEnLocale, calendarDeLocale],
      locale: this.locale,
      headerToolbar: {
        right: 'prev,today,next',
        left: 'title'
      },
      eventSources: [
        {
          events: events,
          display: 'background',
        },
      ],

      eventDidMount: function (info) {
        const openDate = new Date(openDateCalendar);
        if (openDate) {
          const date = openDate.toISOString().split('T')[0];
          const parent_ele = $("td").find(`[data-date='${date}']`);
          parent_ele.addClass('selected-date');
          parent_ele.prop('id','current-selected-date');
        }
      },

      dateClick: function(info) {
        if($("#current-selected-date").length > 0) {
          $("#current-selected-date").removeClass('selected-date');
          $("#current-selected-date").removeAttr('id');
        }
        info.dayEl.id = 'current-selected-date';
        info.dayEl.classList.add('selected-date');
        dateChangeButton.dataset.selectedDate = info.dateStr
        dateChangeButton.click()
      }
    });

    setTimeout(() => {
      calendar.render();
      calendar.gotoDate(openDateCalendar);
    }, 300);

    document.addEventListener('toggleSidebar', () => {
      setTimeout(() => {
        calendar.updateSize();
      }, 550);
    });
  }

  get locale() {
    if (this.element.dataset.locale) {
      return this.element.dataset.locale;
    } else {
      return 'en';
    }
  }
}
