import ApplicationController from './application_controller'
import Sortable from 'sortablejs'

export default class extends ApplicationController {

  connect () {
    super.connect()
    const options = this.fetchOptionsByType;
    this.sortable = Sortable.create(this.element, options)
  }

  // fetch config options
  get selectedGroup() {
    return this.element.dataset.sortingGroup
  }

  // define config options as per requirements
  get fetchOptionsByType() {
    let that = this
    let configOptions = { animation: 150 }
    let typeOptions = {}

    if(this.element.dataset.reorderFor == 'conditions'){
      typeOptions = { onEnd: this.updatePositionOfCondition.bind(this) }
    }else if(this.element.dataset.reorderFor == 'fredfiles'){
      typeOptions = {
        handle: '.sort-icon',
        group: this.selectedGroup,
        dragClass: '.issue_additional_info',
        onSort: function(evt) { $('#positionIssueInfoFields').trigger('click') }
      }
    }else if(this.element.dataset.reorderFor == 'templates'){
      typeOptions = {
        handle: '.sort-icon',
        group: this.selectedGroup,
        dragClass: '.info-field',
        onSort: function(evt) { $('#positionTemplateInfoFields').trigger('click') }
      }
    }else if(this.element.dataset.reorderFor == 'tool_options'){
      typeOptions = {
        handle: '.sort-icon',
        dragClass: '.decision-option-item',
        onSort: function(evt) { $('#positionToolOptions').trigger('click') }
      }
    }

    return {...configOptions, ...typeOptions}
  }

  
  updatePositionOfCondition(event){
    this.stimulate("Position#insert_at", event.item.lastElementChild.dataset.sgid, event.newIndex + 1)
  }
}
