import ApplicationController from './application_controller'

export default class extends ApplicationController {
  
  static targets = ['toolType']

  add_option(event){
    let option_name = $(this.element).find('#new_option_title').val().trim()
    event.target.dataset.new_option_name = option_name

    if(option_name != ''){
      this.stimulate("IssueDecisionTools#add_option", event.target)
    }
  }

  delete_option(event){
    let optionElementSelector = `#${this.element.dataset.optionSelector}`
    let optionElement = $(`#option-list ${optionElementSelector}`)
    let optionIdElement = $(optionElement).find('.option-id')

    if(optionIdElement.length > 0 && (optionIdElement.val() != '')){
      $(optionElement).find('.option-destroyed').val('true')
      optionElement.addClass('invisible')
    }else{
      optionElement.remove()
    }
  }

  toggle_attendees_field(event){
    let attendeesField = $('#decision_tool_form #attendees-list')
    if(this.element.value == 'true'){
      attendeesField.addClass('invisible')
    }else{
      attendeesField.removeClass('invisible')
    }
  }

  validate_deadline_date(event){
    let form = $('#decision_tool_form')
    let tool_data = {}
    tool_data.issue_id = form.find('#issue-scheduled-date').attr('data-issue-id')
    tool_data.condition_id = form.find('#condition-id').val()
    tool_data.deadline_date = form.find('#deadline-date').val()

    this.stimulate("IssueDecisionToolsReflex#validate_deadline_date", tool_data)
  }

  toggle_vote_button(event){
    if($(this.element).find('input.option-choice:checked').length >= 1){
      $(this.element).find('#vote-submit-button').prop('disabled', false)
    }else{
      $(this.element).find('#vote-submit-button').prop('disabled', true)
    }
  }
}
