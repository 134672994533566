$(document).ready(function() {
  $(document).on('hidden.bs.offcanvas',"#offcanvasRightDocument",function(){
    path = window.location.href.split("#")[0] + "#" + event.target.children[0].id 
    Turbolinks.visit(path)

    let urlCleaner = new IndexUrlCleaner({
                      currentLocation: window.location,
                      scrollIdentifier: event.target.children[0].id,
                      paramName: 'deliverable_id'
                    })

    Turbolinks.visit(urlCleaner.getUrl())
  })
})
