import ApplicationController from './application_controller'

export default class extends ApplicationController {

  add_team_member(event){
    let group_user_ids = $(event.target).parents('.add_group_user_section').find('#select_group_user').val()
    event.target.dataset.new_group_user_ids = group_user_ids

    if(group_user_ids != ''){
      this.stimulate("Groups#add_team_member", event.target)
    }
  }

  remove_team_member(event){
    let groupUserElementSelector = `#${this.element.dataset.recordSelector}`
    let groupUserElement = $(`#group-users-list ${groupUserElementSelector}`)
    let groupUserIdElement = $(groupUserElement).find('.record-id')
    let recordUserId = $(groupUserElement).find('.record-user-id').val()
    
    if(groupUserIdElement.length > 0 && (groupUserIdElement.val() != '')){
      $(groupUserElement).find('.record-destroyed').val('true')
      groupUserElement.addClass('d-none')
    }else{
      groupUserElement.remove()
    }

    this.#enableRemovedUserOption(recordUserId)
  }

  toggle_add_user_button(event){
    let value_present = (event.target.value != '')
    let add_user_button = $('#add-new-group-user')

    if(value_present){
      this.#toggle_add_new_user_button(false)
    }else{
      this.#toggle_add_new_user_button(true)
    }
  }

  re_render_selection(event){
    this.#toggle_add_new_user_button(true)
    this.#disableSelectedUserOptions()
  }

  #usersTomSelect(){
    return $("#group_form #select_group_user")[0].tomselect
  }

  #toggle_add_new_user_button(disabled){
    $("#group_form #add-new-group-user").prop('disabled', disabled)
  }

  #disableSelectedUserOptions(){
    // select only added users, and ignore the deleted ones (having class 'invisible')
    let selected_user_records = $("#group_form #group-users-list .selected-record-item:not(.d-none) .record-user-id").get()
    var user_tom_select = this.#usersTomSelect()

    user_tom_select.clear()
    user_tom_select.refreshOptions(false)


    selected_user_records.forEach((user_id)=> {
      let userIdValue = user_id.value
      user_tom_select.options[userIdValue].disabled = true
      user_tom_select.updateOption(userIdValue, user_tom_select.options[userIdValue])
    })
  }

  #enableRemovedUserOption(recordUserId){
    let user_tom_select = this.#usersTomSelect()

    $(`#group_form #select_group_user option[value='${recordUserId}']`).prop('disabled', false)
    user_tom_select.options[recordUserId].disabled = false
    user_tom_select.updateOption(recordUserId, user_tom_select.options[recordUserId])
  }



}