import ApplicationController from './application_controller'

export default class extends ApplicationController {
	
	change_member_status(event) {

    let member_id = event.currentTarget.getAttribute("data-id")
    let org_id = event.currentTarget.getAttribute("data-organization")
    let status = event.currentTarget.getAttribute("data-current-status")
    if (status == 'passive' && this.element.value == 'active'){
      $.rails.ajax({
        type: "GET",
        url: `/invite_teams/external_user?id=${member_id}&org_id=${org_id}`
      })

    }else{
      this.stimulate('InviteTeams#toggle')
    }
  }

  accept_nda_checkbox(event){
    if($(this.element).is(':checked')){
      $("#otp_for_accepting_nda").removeClass('invisible')
      $(".edit_membership_setting #submit_nda_acceptance").prop("disabled", false)
    }else{
      $("#otp_for_accepting_nda").addClass('invisible')
      $(".edit_membership_setting #submit_nda_acceptance").prop("disabled", true)
    }
  }

  toggle_invite_form(event){
    let selected_form = this.element.value
    let normal_form = $('#normalInviteForm')
    let import_form = $('#importInviteForm')

    if(selected_form == 'normal_fields'){
      normal_form.removeClass('invisible')
      import_form.addClass('invisible')
    }else{
      normal_form.addClass('invisible')
      import_form.removeClass('invisible')
    }
  }

}