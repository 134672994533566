$(document).on('turbolinks:load',function() {

  $(".date_info").keydown(function(event) {
    if (event.keyCode === 8) {
      return false;
    }
  });

  $('#add_info_box').click(function(){
    $('.add_fields')[0].click() 
  });

  function check_validation(element, title) {
    if(title == ''){
      let validation_msg = I18n.t('frontend.issues.validations.title_not_present')

      $(element).find('.title-validity-status').text(validation_msg)
      $('.save-addi-info-button').attr('disabled', true)
      return false
    }
    else{
      return true
    }
  }
  
  $('.add-new-additional-info').click(function(){
    let data = { index: this.dataset.index, addi_info_type: this.dataset.addiInfoType }
    $.ajax({
      url: `/additional_infos/new`,
      method: "GET",
      data: data,
      dataType : 'script'
    })
  });

  $('.close_add_info').click(function(){
    $(this).parent().parent().find('div.form-group.additional_info_fields').remove();
  });

  if( typeof(localStorage['meeting_id'])  !== "undefined"){

    $('.meeting-link').removeClass('hide');

    var url = "/protocol/meetings/" + localStorage['meeting_id']
    
    $('.meeting-link').attr("href", url);
  }

  $(document).on('change', 'textarea', function(){
    this.value = this.value.trim();
  });

  // Change comment icon event listener
  document.querySelectorAll('.commentModalLink').forEach(item => {
    item.addEventListener('toggle_icons', event => {
      if(event.detail.changeIcons){
        if(event.detail.isFilled){
          $(event.target).find(".commentFilled").removeClass('invisible')
          $(event.target).find(".commentBlank").addClass('invisible')
        }else if(event.detail.isBlank){
          $(event.target).find(".commentFilled").addClass('invisible')
          $(event.target).find(".commentBlank").removeClass('invisible')
        }
      }
    })
  })

});

$(document).on('click',".group_temp",function(event) {
  addGroupIssue = $(this).parents("#addIssue")
  addGroupIssue.find("#select-group-for-issue").val(this.getAttribute('value'))
  addGroupIssue.find("#issue_group_id").val(this.innerText).attr("new_group", "false")
  addGroupIssue.find("#search_groups").css("display", "none")
  addGroupIssue.find("#group_list").remove()
});

$(document).on('focus',"#addIssue #issue_group_id",function(event) {
  $(this).parents("#addIssue").find("#search_groups").css("display", "block")
});

$(document).on('keydown',"#addIssue #issue_group_id",function(event) {
  if(event.key == "Backspace"){
    groupIssue = $(this).parents("#addIssue")
    groupIssueValueField = groupIssue.find("#select-group-for-issue")
    groupIssueSearchField = groupIssue.find("#issue_group_id")

    if( ((groupIssueValueField.val() != "") && (groupIssueSearchField.attr("new_group") == "false")) || 
        ((groupIssueValueField.val() == "") && (groupIssueSearchField.attr("new_group") == "true")) ){

      groupIssueSearchField.val("").attr("new_templanew_groupte", "false")
      groupIssueValueField.val("")
    }
  }
});

$(document).on('change keyup paste', ".info-title", function(event){
  title = $(this).val()

  const titles = [];
  $("#options .info-title").not($(this.element)).each(function() {
    titles.push($(this).val());
  });

  let element = $(this).parents('.modal-content');

  if (titles.includes(title)){
    let validation_msg = I18n.t('frontend.issues.validations.title_unavailable')

    $(element).find('.title-validity-status').text(validation_msg)
    $(element).find('.title-validity-status').removeClass('text-success').addClass('text-danger')
    $('.save-addi-info-button').attr('disabled', true)
  } else {
    let validation_msg = I18n.t('frontend.issues.validations.title_available')

    $(element).find('.title-validity-status').text(validation_msg)
    $(element).find('.title-validity-status').removeClass('text-danger').addClass('text-success')
    $('.save-addi-info-button').attr('disabled', false)
  }
});

$(document).on('change keyup paste', ".info-option", function(event){
  
  $('.option-value').val($(this).text());

  let field_options = $(this).val().split(",");
  
  if(field_options.length > 40){
    let element = $(this).parents('.modal-content');
    let validation_msg = I18n.t('frontend.issues.validations.maximum_option_error_msg')

    $(element).find('.option-validity-msg').text(validation_msg)
    $('.save-addi-info-button').attr('disabled', true)
  }
})

$(document).on('click', ".edit-additional-info", function(event){
  let element = $(this).parents('.custom-field-block')
  title = $(element).find('.info-title').val()
  value = $(element).find('.info-value').val()
  field_type = $(element).find('.info-field-type').val()
  mandatory = $(element).find('.info-mandatory').val()
  options = $(element).find('.info-option').val()
  color = $(element).find('.info-color').val();
  index = $(element).find('.info-index').val();
  addi_info_type = $(element).find('.info-type').val();
  org_id = $(element).find('.info-org').val();
  id = $(element).find('.info-id').val();
  position = $(element).find('.info-position').val();


  let data = {
    additional_info: {
      id: id,
      title: title,
      organization_id: org_id,
      value: value,
      options: options,
      field_type: field_type,
      color: color,
      mandatory: mandatory,
      position: position,
      index: index,
      addi_info_type: addi_info_type
    }
  }
  
  $("#addAdiitionalInfo").modal('hide');
  $.ajax({
    url: `/additional_infos/edit_additional_info`,
    method: "get",
    data: data,
    dataType : 'script'
  })
});

$(document).on('click', "#add_new_group", function(event){
  add_new_group()
})

$(document).on('click',".add_new_group",function(event) {
  newGroupModal = $(this).parents("#addIssue")
  newGroupModal.find("#select-group-for-issue").val("")
  newGroupModal.find("#issue_group_id").attr("new_group", "true")
  newGroupModal.find("#search_groups").css("display", "none")
  newGroupModal.find("#issue_group_id").val()
  newGroupModal.find("#new_group_name").val(newGroupModal.find("#issue_group_id").val())
  newGroupModal.find("#group_list").remove()
});

$(document).ready(function(){
  $("#toggle-button2").addClass("active");
  $(".tri-state-toggle-button_custom").click(function(){
    $(".tri-state-toggle-button_custom").removeClass("active");
    var id = $(this).attr('id');
    $("#" + id).addClass("active");
    if(id == "toggle-button1"){ 
      $("#" + id).removeClass("white");
      $("#" + id).removeClass("green");
      $("#toggle-button2").removeClass("white");
      $("#toggle-button3").removeClass("green");
      $("#" + id).addClass("red");
    }
    else if (id == "toggle-button2")   {
      $("#" + id).removeClass("red");
      $("#" + id).removeClass("green");
      $("#toggle-button1").removeClass("red");
      $("#toggle-button3").removeClass("green");
      $("#" + id).addClass("white");
    }
    else if (id == "toggle-button3") {  
      $("#" + id).removeClass("white");
      $("#" + id).removeClass("red");
      $("#toggle-button1").removeClass("red");
      $("#toggle-button2").removeClass("white");
      $("#" + id).addClass("green");
    }  
    
  });
  
  $(document).on('reload_overview_page', '#overview_page', () => {
    Turbolinks.visit();
  })
});

$(document).on('hidden.bs.offcanvas',"#IssueComment",function(event){
  let urlCleaner = new IndexUrlCleaner({
                    currentLocation: window.location,
                    scrollIdentifier: event.target.children[0].id,
                    paramName: 'issue_id'
                  })

  Turbolinks.visit(urlCleaner.getUrl())
})
