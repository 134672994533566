import ApplicationController from './application_controller'

export default class extends ApplicationController {

  new_meeting() {
    $.rails.ajax({
      type: "GET",
      url: `/protocol/meetings/new?date=${this.element.dataset.date}`
    })
  }

  destroy_meeting(event) {
    event.preventDefault();

    const status = confirm(I18n.t('frontend.are_you_sure'))
    if(!status){
      return false;
    }else{
      const el = event.currentTarget
      const id = el.getAttribute("data-id");
      this.stimulate("MeetingsReflex#destroy_meeting")
    }

  }
}
