import ApplicationController from "./application_controller";
import * as EasyMDE from 'easymde';
import "easymde/dist/easymde.min.css"
window.EasyMDE = EasyMDE;

export default class extends ApplicationController {
  
  static targets = ['easymarkdown']

  connect() {
    super.connect();
    if (this.hasEasymarkdownTarget && !this.easymarkdownTarget.disabled) {
      var dom_element = this.easymarkdownTarget
      this.easyMDE = new EasyMDE({
        element: dom_element,
        minHeight: "100px",
        autoRefresh:true,
        spellChecker:false,
        toolbar: getToolbarConfig()
      })

      this.easyMDE.codemirror.on("change", () => {
        $(`#${dom_element.id}`).val(this.easyMDE.value());
      });
    }

    function getToolbarConfig() {
      let toolbarConfig = [
        { name: "bold", action: EasyMDE.toggleBold, className: "fa fa-bold", title: I18n.t('frontend.markdown.bold') },
        { name: "italic", action: EasyMDE.toggleItalic, className: "fa fa-italic", title: I18n.t('frontend.markdown.italic') },
        { name: "heading", action: EasyMDE.toggleHeadingSmaller, className: "heading-text", title: I18n.t('frontend.markdown.heading') },
        "|",
        { name: "quote", action: EasyMDE.toggleBlockquote, className: "fa fa-quote-left", title: I18n.t('frontend.markdown.quote') },
        { name: "unordered-list", action: EasyMDE.toggleUnorderedList, className: "fa fa-list-ul", title: I18n.t('frontend.markdown.unordered-list') },
        { name: "ordered-list", action: EasyMDE.toggleOrderedList, className: "fa fa-list-ol", title: I18n.t('frontend.markdown.ordered-list') },
        "|",
        { 
          name: "link", 
          action: function(editor) {
            $('#addLinkInMarkdown').modal('show');
          
            var linkSubmitHandler = function(e) {
              e.preventDefault();
              var selectedText = $('#linkText').val();
              var link = $('#linkURL').val();
            
              if (selectedText && link) {
                var replacement = '[' + selectedText + '](' + link + ')';
                editor.codemirror.replaceSelection(replacement);
              }
          
              $('#addLinkInMarkdown').modal('hide');
              $('#linkSubmit').off('click', linkSubmitHandler);
              $('#linkText').val('');
              $('#linkURL').val('');
            };
          
            $('#addLinkInMarkdown').one('shown.bs.modal', function() {
              $('#linkSubmit').one('click', linkSubmitHandler);
            });
          }, 
          className: "fa fa-link", 
          title: I18n.t('frontend.markdown.link')
        },
        { name: "table", action: EasyMDE.drawTable, className: "fa fa-table", title: I18n.t('frontend.markdown.table') },
        "|",
        { name: "preview", action: EasyMDE.togglePreview, className: "preview-button no-disable", title: I18n.t('frontend.markdown.preview') },
        { name: "side-by-side", action: EasyMDE.toggleSideBySide, className: "fa fa-columns no-disable no-mobile", title: I18n.t('frontend.markdown.side-by-side') },
        { name: "fullscreen", action: EasyMDE.toggleFullScreen, className: "fa fa-arrows-alt no-disable no-mobile", title: I18n.t('frontend.markdown.fullscreen') },
        "|",
        {
          name: "markdownGuide",
          action: () => {
            window.open("https://www.markdownguide.org/basic-syntax/", "_blank");
          },
          className: "fa fa-question-circle",
          title: I18n.t('frontend.markdown.markdownGuide'),
        },
      ]
      return toolbarConfig;
    }
  }
}
