 $(document).on('turbolinks:load',function(){
    
    $(document).on('hidden.bs.offcanvas',"#offcanvasRightCondition",function(){
      path = window.location.href.split("#")[0] + "#" + event.target.children[0].id 
      Turbolinks.visit(path)
    })
  });

$(document).on('hidden.bs.offcanvas',"#offcanvasRightCondition",function(event){
  let urlCleaner = new IndexUrlCleaner({
                    currentLocation: window.location,
                    scrollIdentifier: event.target.children[0].id,
                    paramName: 'condition_id'
                  })

  Turbolinks.visit(urlCleaner.getUrl())
})
