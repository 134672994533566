$(document).ready(function() {
 $(document).on('click',".issue_dependency_link",function(event){
  var this_var = this
    jQuery.ajax({
      url: '/check_validity_for_issue_dependency',
      type: 'get',
      contentType: 'application/json',
      data: ({ issue_id: $("#issue_dependency_issue_id").val(),
               selected_issue_id: this.id}),
      success: function (result) {
        if(!result){
          jQuery.ajax({
            url: '/get_issue_status',
            type: 'get',
            contentType: 'application/json',
            data: ({ issue_id: this_var.id }),
            success: function (response) {
              document.getElementById("depedency_status").value = response["status"]
            }
          });
          clickhandler(this_var);
          $("#issue_select_error").hide();
        }else{
          $("#issue_select_error").show();
        }
      }
    });
    
  });

  $(document).on('keyup',"#dependency_link",function(event){
    var key = event.keyCode || event.charCode;
      if( key == 8 || key == 46 ){
        var x = $(this).val().split("#")
        if(x.length > 0){
          var temp = [];
          for(let i = 0 ;i < x.length ; i++){  
            if(selectedIssues.includes(x[i].trim())){
              temp.push(x[i].trim())
            }
          }
          selectedIssues = temp
        }
        if(Object.keys(title_id_json).length != 0){
          set_title();
          set_title_id_json();
          set_dom_elements();
          document.getElementById("depedency_status").value = "draft"
        }
      }
  });

});

function clickhandler(e,id){    
  var enteredValue = e.innerText.split("#");
  if(enteredValue.length > 0){
    var temp = [];
    for(let i = 0 ;i < enteredValue.length ; i++){
      if(selectedIssues.includes(enteredValue[i].trim())){
        temp.push(enteredValue[i].trim())
      }
    }
    selectedIssues = temp      
  }
  selectedIssues.push('('+e.innerText+')')
  title_id_json[e.innerText.split(":")[1].trim()] = e.id;
  set_title();
  set_title_id_json();
  set_dom_elements()
  
  document.getElementById("show_dependency_references").style.display='none'
}

function set_title(){
  if(selectedIssues.length > 0){
    var temp3 = [];
    for(let i = 0 ;i < selectedIssues.length ; i++){
        temp3.push(selectedIssues[i].split(":")[1].slice(0,-1).trim())
    }
    titles = temp3;
  }else{
    titles = [];
  }
}

function set_title_id_json(){
  var json_obj = {}
  if(titles.length > 0){
    for(let i = 0 ; i < titles.length ; i++){
      if(title_id_json.hasOwnProperty(titles[i])){
        json_obj[titles[i]] = title_id_json[titles[i]]
      }
    }
  }
  title_id_json = json_obj
}

function set_dom_elements(){
  document.getElementById("dependency_link").value = '#'+selectedIssues.join(' #')
  document.getElementById("title_id").value = JSON.stringify(title_id_json)
}
