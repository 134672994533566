import ApplicationController from './application_controller'

export default class extends ApplicationController {

  toggleSidebarOnClick() {
    let sidebarComponentTarget = document.body;
    let adminSubmenuTarget = document.getElementById('administration_toggle');

    ['hover_out', 'small'].forEach(c => {
      sidebarComponentTarget.classList.toggle(c);
    })

    adminSubmenuTarget.classList.toggle('sub-menu');

    if(sidebarComponentTarget.classList.contains('small')){
      localStorage.removeItem('collapsed');
      localStorage.removeItem('arrow');

    }else{
      localStorage.setItem('collapsed', 'false');
    }
    var collapsedValue = localStorage.getItem('collapsed')
    if(collapsedValue == null){
      $('#sidebar-arrow').addClass("fa fa-chevron-right")
      $('#sidebar-arrow').removeClass("fa fa-chevron-left")
    }else{
      $('#sidebar-arrow').removeClass("fa fa-chevron-right")
      $('#sidebar-arrow').addClass("fa fa-chevron-left")
    }
    document.dispatchEvent(new Event('toggleSidebar'));
  }
}
